import React from 'react';
import {BrowserRouter, Route} from 'react-router-dom'; //Enables routing between webapp pages
import './css/style.css';
// import Navbar from './components/navbar'; //imports Navigation Bar component
import Home from './components/home';     //imports Home Page component
import Signup from './components/signup';
import SignupErr from './components/signupErr';
import Signin from './components/signin';
import SigninErr from './components/signinErr';
import Connect from './components/connect';
import Connectgtx from './components/connectgtx';
import Navbar2 from './components/navbar2';


function App() {
  return (
    <BrowserRouter basename="/">
      <div className="App">
        <Navbar2 title="DAYZERO KILLFEED" />
        <Route exact path="/" render={() => <Home title="HOME"/>} />
        <Route path="/signup" render={() => <Signup title="Register For Access To Our Tools"/>} />
        <Route path="/signupErr" render={() => <SignupErr title="Register For Access To Our Tools"/>} />
        <Route path="/signin" render={() => <Signin title="Login and Connect Your Nitrado Account"/>} />
        <Route path="/signinErr" render={() => <SigninErr title="Login and Connect Your Nitrado Account"/>} />
        <Route path="/connect" render={() => <Connect title="Connect Your Nitrado Account"/>} />
        <Route path="/connectgtx" render={() => <Connectgtx title="Connect Your GTX Account"/>} />
        <Route path="/connect2" component={() => { window.location.href = 'https://oauth.nitrado.net/oauth/v2/auth?redirect_uri=http://localhost:3000&client_id=1199_aUwtm2svf47mQhboT_wG2hEq1ocy8EEQ1rRe3afae-_YWTh7Oadws1GwVpPY&response_type=code&scope=service user_info ssh_keys&state=id10t';
      return null}}/>       
      </div>
    </BrowserRouter>
  );
}

export default App;
