import React from 'react';
import './LoginForm/LoginForm.css';
import LoginForm from './LoginForm/LoginForm';


const Signin = (props) => (
    <div className="main-content">
        <div className="container">
                <h2>{props.title}</h2>
                <LoginForm/>
                <div className="signin-image"></div>
                <div className="signup-image"></div>
                <div className="thumbnail-container">
                     <div className="signin-thumbnail-1"></div>
                     <div className="signin-thumbnail-2"></div>
                </div>     
        </div>
    </div>
    
);

export default Signin;