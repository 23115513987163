import React from 'react';
import './RegistrationForm/RegistrationForm.css';
import RegistrationForm from './RegistrationForm/RegistrationForm';


const Signup = (props) => {

    return (
        <div className="main-content">
            <div className="container">
                <h2>{props.title}</h2>
                <RegistrationForm />
                <div className="signup-image"></div>               
            </div>
            <div className=".content-p1">
                <>*After registration you will recieve a email with instructions within 24 hours*.</>
            </div> 
        </div>
    );
    
};


export default Signup;