import React from 'react';
import RequestForm2 from './RequestForm/RequestForm2';
const Connectgtx = (props) => (
    <div className="main-content">
        <div className="container">
                <h2>{props.title}</h2>
                <div className="connect-image"></div>
                <RequestForm2/>
                <div>
                     {/* <div className="connect-thumbnail-1"></div>
                     <div className="connect-thumbnail-2"></div> */}
                </div>     
                
        </div>
    </div>
    
);

export default Connectgtx;