import React from 'react';
import {NavLink} from 'react-router-dom'; //allows pages to be linked to hyperlinks (<li>)
const Navbar2 = (props) => (

    <nav>
        <h2 className="logo"><NavLink class="logo-link" to="/">{props.title}</NavLink></h2>
        <ul className="nav-menu">
            <li><NavLink className="nav-menu__link" exact to="/">Home</NavLink></li>
            <li><NavLink className="nav-menu__link" to="/signup">Register</NavLink></li>
            {/* <li><NavLink className="nav-menu__link" to="/signin">Login</NavLink></li> */}
            <li><NavLink className="nav-menu__link" to="/connect">Nitrado</NavLink></li>
            <li><NavLink className="nav-menu__link" to="/connectgtx">GTX</NavLink></li>
            {/* <li><NavLink className="nav-menu__link" to="/connect">Connect</NavLink></li> */}
        </ul>              
    </nav>
);

export default Navbar2;