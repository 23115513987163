import React from 'react';
import './RegistrationForm/RegistrationForm.css';


const SigninErr = (props) => {

    return (
        <div className="main-content">
            <div className="container">
                <h2>{props.title}</h2>
                <div className=".main-content h2">
                <>Unable to Authenticate.....Please Try Aagin...</>
                </div>             
            </div>
        </div>
    );
    
};

export default SigninErr;