import React, {useState} from 'react';
import axios from "axios";
import { withRouter } from "react-router-dom";
import {options} from '../../constants/apiContants';
// import {getData} from '../../constants/apiContants';
const url = "https://dayzerokillfeed.com:3000/serverDetails/";


function RequestForm2(props) {
    const [state , setState] = useState({
        address: "",
        port: "",
        ftp_u: "",
        userId: "",
        email: "",
        password: "",
        ftp_p: "",
        guildID: "",
        region: "",
    })
    const handleChange = (e) => {
        const {id , value} = e.target   
        setState(prevState => ({
            ...prevState,
            [id] : value
        }))
    }
    const sendDetailsToServer = () => {
        if(state.address.length && state.port.length && state.ftp_u.length && state.userId.length && state.email.length && state.password.length && state.ftp_p.length && state.guildID.length && state.region.length) {
            axios.post("https://dayzerokillfeed.com:3000/users/login",{"email": state.email, "password": state.password})
            .then((res) => {
                if(res.status >= 200 && res.status < 300) {
                    const newToken = res.data.token;
                    axios.post(url, {"address": state.address, "port": state.port, "ftp_u": state.ftp_u, "region": state.region
                    , "userId": state.userId, "ftp_p": state.ftp_p, "guildID": state.guildID, options}, { headers: {'Authorization': 'Bearer '+ newToken }})
                    .then((res) => {
                        if(res.status >= 200 && res.status < 300) {
                            setState(prevState => ({
                                ...prevState,
                                return:'Credentials Saved Successfully',
                                render: res.status.data
                            }))
                            redirectToHome();             
                        }
                    })
                }else {
                    redirectToError();
                }
            })
           .catch(function (error) {
            console.log(error);
            redirectToError();
            });
           
        } 
       
        
    }

    const handleSubmitClick = (e) => {
        e.preventDefault();
        if(!state.address | !state.port | !state.ftp_u | !state.userId | !state.password | !state.email | !state.ftp_p | !state.guildID| !state.region) {
            redirectToError()    
        } else {
            sendDetailsToServer()
            
        }
    }

    const redirectToHome = () => {
        props.history.push('/'); 
    }

    const redirectToError = () => {
        props.history.push('/SignupErr'); 
    }

    return(
        <div className="card col-12 col-lg-4 login-card mt-2 hv-center">
            <form>
                <div className="form-group text-left">
                    <label htmlFor="example@example.com">Email</label>
                    <input type="email" 
                       className="form-control" 
                       id="email" 
                       aria-describedby="emailHelp" 
                       placeholder="Enter your email" 
                       value={state.email}
                       onChange={handleChange}
                    />
                    <small id="emailHelp" className="form-text text-muted">Use your registration email</small>
                </div>
                <div className="form-group text-left">
                    <label htmlFor="abc123">Password</label>
                    <input type="password" 
                       className="form-control" 
                       id="password" 
                       aria-describedby="emailHelp" 
                       placeholder="Enter your password" 
                       value={state.password}
                       onChange={handleChange}
                    />
                    <small id="emailHelp" className="form-text text-muted">Use your registration password</small>
                </div>
                <div className="form-group text-left">
                    <label htmlFor="exampleInputPassword1">User ID</label>
                    <input type="userId" 
                        className="form-control" 
                        id="userId" 
                        placeholder="Enter your K1llFeed User ID"
                        value={state.userId}
                        onChange={handleChange} 
                    />
                    <small id="emailHelp" className="form-text text-muted">You can request this from us if you dont know it.</small>
                </div>
                <div className="form-group text-left">
                    <label htmlFor="exampleInputPassword1">Connection Address</label>
                    <input type="address" 
                        className="form-control" 
                        id="address" 
                        placeholder="Enter Your GTX connection address"
                        value={state.address}
                        onChange={handleChange} 
                    />
                    <small id="emailHelp" className="form-text text-muted">(Required) IP address displayed in Server Management.</small>
                </div>
                <div className="form-group text-left">
                    <label htmlFor="98765">FTP Port</label>
                    <input type="port" 
                       className="form-control" 
                       id="port" 
                       aria-describedby="emailHelp" 
                       placeholder="Enter your GTX port number" 
                       value={state.port}
                       onChange={handleChange}
                    />
                    <small id="emailHelp" className="form-text text-muted">(Required) Port number displayed in Server / FTP Details </small>
                </div>
                <div className="form-group text-left">
                    <label htmlFor="98765">Server Region</label>
                    <input type="region" 
                       className="form-control" 
                       id="region" 
                       aria-describedby="emailHelp" 
                       placeholder="Enter your GTX Server Region" 
                       value={state.region}
                       onChange={handleChange}
                    />
                    <small id="emailHelp" className="form-text text-muted">(Required) Frankfurt, Los_Angeles, London, Miami, New_York, Singapore, Sydney, Moscow</small>
                </div>
                <div className="form-group text-left">
                    <label htmlFor="exampleInputPassword1">FTP Username</label>
                    <input type="ftp_u" 
                        className="form-control" 
                        id="ftp_u" 
                        placeholder="Enter your GTX FTP Username"
                        value={state.ftp_u}
                        onChange={handleChange} 
                    />
                    <small id="emailHelp" className="form-text text-muted">(Required) We'll never share your credentials with anyone else.</small>
                </div>
                <div className="form-group text-left">
                    <label htmlFor="exampleInputPassword1">FTP Password</label>
                    <input type="ftp_p" 
                        className="form-control" 
                        id="ftp_p" 
                        placeholder="Enter your GTX FTP Password"
                        value={state.ftp_p}
                        onChange={handleChange} 
                    />
                    <small id="emailHelp" className="form-text text-muted">(Required) We'll never share your credentials with anyone else.</small>
                </div>
                <div className="form-group text-left">
                    <label htmlFor="exampleInputPassword1">Discord GuildID/ServerID</label>
                    <input type="guildID" 
                        className="form-control" 
                        id="guildID" 
                        placeholder="Enter your GuildID/ServerID"
                        value={state.guildID}
                        onChange={handleChange} 
                    />
                    <small id="emailHelp" className="form-text text-muted">(Required) Right-Click your Discord Server Logo to get this ID</small>
                </div>
                <button type="submit" className="btn btn-primary" onClick={handleSubmitClick}>Submit</button>
            </form>
            <div className="alert alert-success mt-2" style={{display: state.successMessage ? 'block' : 'none' }} role="alert">
                {state.successMessage}
            </div>
            
        </div>
    )
}

export default withRouter(RequestForm2);
