import React from 'react';
const Home = (props) => (
    <div className="main-content">
        <div className="container">
            <h2>{props.title}</h2>
            <h4>
                <>The most comprehensive, reliable, affordable and simple to use DayZ Discord Tool for your DayZ Community Server needs.</>
            </h4>
            <div className="home-image"></div>
        </div>
        <div className="container">
            {/* <div className=".content-p2">
                <ul>
                    <li>Kill Feed</li>
                    <li>Statistics</li>
                    <li>Leader Boards</li>
                    <li>Clan System</li>
                    <li>Digital Currency</li>
                    <li>Economy System (Jobs/Skills)</li>
                    <li>Bounty Hunting System</li>
                    <li>Connect/Disconnect Feed</li>
                    <li>Survivor Location Feed (All Currently Connected)</li>
                </ul>

                <ul>
                    <li>Current Location Ping (whereami)</li>
                    <li>Zone/Raid Alarm (Detects Raiding Actions)</li>
                    <li>Restricted Zones Alarm (Detects Within Vicinity)</li>
                    <li>Discord Strike System</li>
                    <li>Subscription System</li>
                    <li>Add/Remove Whitelist, Banlist, Priority List</li>
                    <li>Auto Server Restart (Detects Stopped Server Status)</li>
                    <li>Under-map Glitch Detection</li>
                </ul>
            </div>  */}
            <div className="thumbnail-container">
                 <div className="home-thumbnail-1"></div>
                    <div className="home-thumbnail-3"></div>
                    <div className="home-thumbnail-2"></div>
                    <div className="home-thumbnail-4"></div>
            </div> 
        </div>
    </div>
);

export default Home;