import React from 'react';
import './RegistrationForm/RegistrationForm.css';


const SignupErr = (props) => {

    return (
        <div className="main-content">
            <div className="container">
                <h2>{props.title}</h2>
                <div className=".main-content h2">
                <>A Error Has Occured......Verify Credentials And Try Again!</>
                </div>             
            </div>
        </div>
    );
    
};

export default SignupErr;